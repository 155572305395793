<script setup lang="ts">
import VText from "@magnit/core/src/components/VText/VText.vue";
import VModal from "@magnit/core/src/components/VModal/VModal.vue";
import VButton from "@magnit/core/src/components/VButton/VButton.vue";
import { captureException } from "@sentry/nuxt";
import type { IPollProduct } from "~/typings/api/";

const router = useRouter();
const { isActive, isOpen, close, open, name: modalName } = useProductsModal();

const { requestUserPoll, markUserPoll } = pollsApi();
const { data: pollData } = await requestUserPoll();

const transactionId = computed<string>(
  () => pollData.value?.metadata?.transaction_id || "",
);
const products = computed<IPollProduct[]>(
  () =>
    pollData.value?.native?.question_groups.map((item) => item.product) || [],
);

onMounted(async () => {
  if (products.value.length) {
    open();
  }
});

const closeModal = () => {
  close();
  try {
    markUserPoll();
  } catch (e) {
    captureException(e);
  }
};

const rateProducts = () => {
  router.push(`${Routes.ProfileTransactions}/${transactionId.value}`);
  closeModal();
};
</script>

<template>
  <VModal
    :id="modalName"
    :active="isActive"
    :open="isOpen"
    title="Как вам товары?"
    :type="{ xs: 'bottom-sheet', m: 'modal' }"
    wrapper-class="products-modal"
    @close="closeModal"
  >
    <template #default>
      <VText
        tag="ul"
        font="body-large-accent"
        color="invert-primary"
        class="popup-list"
      >
        <li v-for="item in products" :key="item.code" class="popup-list__item">
          {{ item.name }}
        </li>
      </VText>
    </template>
    <template #footer>
      <VButton full-width @click="rateProducts">
        Оценить
      </VButton>
    </template>
  </VModal>
</template>

<style lang="postcss">
.products-modal {
  pointer-events: none;
  z-index: 1 !important;

  .pl-modal-scrim {
    display: none;
  }

  .pl-modal {
    pointer-events: auto;
    padding-bottom: 50px;
    box-shadow: 0 0 24px 0 rgb(56 56 56 / 30.2%);
  }

  .pl-modal-footer {
    border-top: none;
  }

  .popup-list {
    list-style: disc inside;
    padding-left: 10px;
    margin-top: var(--pl-unit-x4);
    color: var(--pl-text-primary);

    &__item {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:not(:last-child) {
        margin-bottom: var(--pl-unit-x1);
      }
    }
  }
}
</style>
